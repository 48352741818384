@import '../semantic/globals/site.variables';

.portal-layout {
  &__menu {
    background: @mainMenuBackground;
    position: fixed;
    width: 100%;
    z-index: 2;

    &-top {
      padding: 20px 25px 0 25px;
    }

    &-bottom {
      margin: 0;
      padding: 0 15px;
    }
  }

  &__content {
    padding: 161px 15px 0 15px;
  }
}
