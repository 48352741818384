@import '../../semantic/globals/site.variables';
@import '../../styles/responsive';

@menuWidth: 250px;
@menuPadding: 25px;

// TODO: hook this up to vars somehow?

.sidebar-layout {
  &-menu {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: @menuWidth;
    height: 100vh;
    padding: 15px 0;
    background-color: @mainMenuBackground;
    border-right: 1px solid rgba(0, 0, 0, 0.05);

    &-wrap {
      padding-left: 25px;
    }

    &--offscreen {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      transition: ease-in-out 200ms transform;
      transform: translateX(-@menuWidth);
    }

    &--offscreen&--open {
      transform: translateX(0);
    }

    &--dark {
      color: #fff;
      background-color: #1e1e1e;
      border-right: none;
    }
  }

  &-header:first-child {
    margin-top: 0;
  }

  &-header {
    text-transform: uppercase;
    font-size: 11px;
    color: #abadb3;
    font-weight: bold;
    margin: 5px 0 5px 25px;
  }

  &-link {
    display: block;
    padding: 0.7em 22px;
    color: @textColor;
    border-left: 3px solid transparent;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: inherit;
      background-color: rgba(0, 0, 0, 0.05);
    }

    &.active {
      border-left: 3px solid @primaryColor;
      background-color: rgba(@primaryColor, 0.1);
    }

    .icon {
      margin-right: 10px;
    }

    .sidebar-layout-accordion & {
      padding-left: 40px;
    }
  }

  &-item {
    display: block;
    margin: 0 23px 15px 23px;
    padding: 0 2px 0.6em 2px;
    color: @textColor;
    border-bottom: 1px solid @borderColor;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: inherit;
      border-color: rgba(34, 36, 38, 0.35);
    }

    .icon {
      margin-right: 10px;
    }

    .right {
      float: right;
      top: 3px;
      margin-right: 5px;
    }
  }

  &-accordion {
    display: none;

    &--active {
      display: block;
    }
  }

  &-divider {
    height: 1px;
    margin: 0 0 15px -@menuPadding;
    background-color: #ccc;
  }

  &-content {
    padding: 15px 0;

    &--offset {
      margin-left: @menuWidth;
      padding: 20px 25px 0 25px;
    }
  }

  &-mobile {
    background: @mainMenuBackground;
    padding: 7px 15px 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin: -15px 0 10px 0;

    @media @desktop {
      display: none;
    }
  }

  &-trigger {
    cursor: pointer;

    &--hidden {
      display: none;
    }
  }

  &__dimmer {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(193, 201, 210, 0.7);
    transition: linear 200ms opacity;
    opacity: 0;
    pointer-events: none;

    &--open {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

@media only screen and (max-width: 600px) {
  .ui.definition.table tr td:first-child:not(.ignored),
  .ui.definition.table tr td.definition {
    background: none;
  }
}
